<template>
  <div>
    <b-table-simple
      hover
      small
      caption-top
      responsive
      class="table-simple scroll-table"
    >
      <colgroup><col><col></colgroup>
      <colgroup><col><col><col></colgroup>
      <colgroup><col><col></colgroup>
      <b-thead>
        <b-tr>
          <b-th
            colspan="1"
            class="style-table-thread"
          >
            TT
          </b-th>
          <b-th colspan="1">
            CHỈ TIÊU
          </b-th>
          <b-th colspan="1">
            ĐƠN VỊ
          </b-th>
          <b-th colspan="1">
            KỲ TRƯỚC
          </b-th>
          <b-th colspan="1">
            KỲ BÁO CÁO
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="(item, index) in dataList"
          :key="index"
        >
          <b-td
            class="pb-1 pt-1"
            :class="item.isBold ? 'bold-text' : '' || item.isItalic ? 'italic-text' : ''"
          >
            {{ item.stringOrder }}
          </b-td>
          <b-td
            class="pb-1 pt-1"
            :class="item.isBold ? 'bold-text' : '' || item.isItalic ? 'italic-text' : ''"
          >
            {{ item.name }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.unit }}
          </b-td>
          <b-td class="pb-1 pt-1">
            <b-form-input
              v-if="item.isEdit"
              v-model="item.lastPeriod"
              type="number"
              @change="handleCountlastPeriod($event, item.titleReportId, item.currentPeriod)"
            />
          </b-td>
          <b-td class="pb-1 pt-1">
            <b-form-input
              v-if="item.isEdit"
              v-model="item.currentPeriod"
              type="number"
              @change="handleCountcurrentPeriod($event, item.titleReportId, item.lastPeriod)"
            />
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

  </div>
</template>

<script>
import {
  BTableSimple,
  BTbody,
  BTr,
  BTh,
  BTd,
  BThead,
  BFormInput,
} from 'bootstrap-vue'

import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BThead,
    BFormInput,
  },

  props: {
    collectReportId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      dataList: [],
      currentPeriod: 0,
      lastPeriod: 0,
    }
  },
  created() {
    this.fetchData(this.collectReportId)
  },
  methods: {
    // Fetch data
    async fetchData(collectReportId) {
      const param = {
        collectReportId,
      }
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_LABOR_MARKET_INFORMATION_ID, {
        params: param,
      })
      this.dataList = data
      this.$hideLoading()
    },

    async handleCountlastPeriod(value, id, currentPeriod) {
      this.lastPeriod = value
      const model = {
        collectReportId: this.collectReportId,
        titleReportId: id,
        lastPeriod: Number(this.lastPeriod),
        currentPeriod: Number(currentPeriod),
      }
      await axiosApiInstance.post(ConstantsApi.CREATE_LABOR_MARKET_INFORMATION_ID, model)
    },
    async handleCountcurrentPeriod(value, id, lastPeriod) {
      this.currentPeriod = value
      const model = {
        collectReportId: this.collectReportId,
        titleReportId: id,
        currentPeriod: Number(this.currentPeriod),
        lastPeriod: Number(lastPeriod),
      }
      await axiosApiInstance.post(ConstantsApi.CREATE_LABOR_MARKET_INFORMATION_ID, model)
    },
  },
}
</script>

<style lang="scss">
.scroll-table {
  overflow-y: scroll;
  height: 500px;
}
.table-simple {
  .table {
    thead {
      tr {
        th {
          background-color: rgba(34, 127, 244, 0.12) !important;
        }
      }
    }
  }
  .style-table-thread {
    width: 50px;
  }
  .bold-text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #2E3A4A !important;
  }
  .italic-text {
    font-family: 'Montserrat';
    font-style: italic;
    font-size: 14px;
    color: #2E3A4A !important;
  }
}
</style>
